import React, { useMemo } from "react";

import Link from "next/link";

import Header from "@/components/Header";
import Button from "@/components/interactives/Button";

import isApp from "@/helpers/isApp";
import { tailwindCascade } from "@/helpers/tailwindCascade";
import trans from "@/helpers/trans";

import { ANSWER2, ANSWER3, BLACK, GENERATOR_BLUE } from "@/colors";
import { QUIZ_VOTEMODE_ID } from "@/constants";

function BannerWrapper({ src, href, className, children }) {
	return (
		<Link
			className={tailwindCascade(
				"border-3 h-36 relative w-full md:w-1/3 border-black border-solid rounded-md z-0 overflow-hidden cursor-pointer group",
				className
			)}
			href={href}
			shallow={true}
		>
			<img className="absolute top-0 left-0 z-0 block object-cover w-full h-full" src={src} alt="" />
			<div className="z-1 -shadow-inner-hard-1 relative flex flex-col items-center justify-center w-full h-full space-y-2 text-center">
				{children}
			</div>
			<div className="z-2 group-hover:bg-opacity-20 absolute top-0 left-0 w-full h-full bg-white bg-opacity-0" />
		</Link>
	);
}

export default function NotFound({ title = null, text = null, dark = false, className }) {
	const numberOfBanners = useMemo(() => {
		if (isApp) {
			return 1;
		}
		return 3;
	}, []);

	if (title === null || title === undefined) {
		title = trans("The page you're looking for can't be found.");
	}

	if (text === null || text === undefined) {
		text = trans("Please check the URL and try again.");
	}

	return (
		<div className={tailwindCascade("w-full h-full mx-0 pt-16 md:pt-8 overflow-x-hidden", className)}>
			<div className="w-full">
				<div className="md:space-y-16 container flex flex-col items-center pb-8 mx-auto space-y-0">
					<div
						className={tailwindCascade(
							"rounded-xl md:p-8 flex flex-col items-center justify-center w-full gap-2 p-6",
							{
								"bg-black-10 text-black": !dark,
								"bg-petrol-darker text-white": dark,
							}
						)}
					>
						{title && <div className="pb-4 text-2xl font-bold leading-none text-center">{title}</div>}
						{text && <div className="text-base font-bold leading-none text-center">{text}</div>}
					</div>
					<div className="md:flex-row md:space-x-4 md:space-y-0 md:pt-4 md:pb-8 flex flex-col justify-center w-full pt-16 pb-8 space-y-4">
						{!isApp && (
							<BannerWrapper
								className={tailwindCascade("bg-white", {
									"md:w-1/2": numberOfBanners <= 2,
									"md:w-1/3": numberOfBanners === 3,
								})}
								href="/"
								src="/images/backgrounds/play-startpage.png"
							>
								<Button
									elementType="div"
									disabled={true}
									disabledOpacity={false}
									color={ANSWER2}
									className="lg:text-lg w-auto mt-3 text-sm"
									border={true}
								>
									{trans("Start page")}
								</Button>
								<h3 className="block px-4 text-xs font-black leading-none">
									{trans("Choose what to play next")}
								</h3>
							</BannerWrapper>
						)}

						<BannerWrapper
							className={tailwindCascade("md:bg-answer3", {
								"md:w-1/2": numberOfBanners <= 2,
								"md:w-1/3": numberOfBanners === 3,
							})}
							href={`/play/${QUIZ_VOTEMODE_ID}/`}
							src="/images/backgrounds/play-vote.png"
						>
							<Button
								elementType="div"
								disabled={true}
								disabledOpacity={false}
								color={ANSWER3}
								className="lg:text-lg w-auto mt-3 text-sm"
								border={true}
							>
								{isApp ? trans("Continue in vote mode") : trans("Vote mode")}
							</Button>
							<h3 className="block px-4 text-xs font-black leading-none">
								{trans("Let your audience decide")}
							</h3>
						</BannerWrapper>
						{!isApp && (
							<BannerWrapper
								className={tailwindCascade("md:bg-petrol-darker", {
									"md:w-1/2": numberOfBanners <= 2,
									"md:w-1/3": numberOfBanners === 3,
								})}
								href="/play/mode/ai/"
								src="/images/backgrounds/play-matrix.png"
							>
								<Button
									elementType="div"
									disabled={true}
									disabledOpacity={false}
									color={GENERATOR_BLUE}
									className="lg:text-lg w-auto mt-3 text-sm"
									border={true}
								>
									{trans("AI Generator")}
								</Button>
								<Header
									className="text-generator-blue px-4 text-xs font-black leading-none"
									textStrokeColor={BLACK}
									textStrokeWidth={2}
								>
									{trans("Type a subject to generate a quiz")}
								</Header>
							</BannerWrapper>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
